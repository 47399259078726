/* CSS for openscreen.uio.no */

@import "../../../../../../lib/uio2/css/base/base.less";
@import "../../../../../../lib/uio2/css/typography.less";
@import "../../../../../../lib/uio2/css/grid.less";
@import "../../../../../../lib/uio2/css/components.less";
@import "../../../../../../lib/uio2/css/documents.less";
@import "../../../../../../lib/uio2/css/listings.less";
@import "../../../../../../lib/uio2/css/search.less";

@responsiveConsentBreakpoint: ~"only screen and (max-width: 768px)";
@tabletConsentBreakpoint: ~"only screen and (max-width: 1070px)";
@import '../../../../../groups/all/src/resources/css/consent.less';

@import "../../../../../../lib/uio2/css/frontpage-edit.less";
@import "../../../../../../lib/uio2/css/embedded.less";
@import "../../../../../../lib/uio2/css/print.less";

@import '../../../../../groups/all/src/resources/css/head-language-switch.less';

@import "../../../../../groups/design-uio2/src/resources/css/color-profile/color-profile.less";
@import "../../../../../groups/design-uio2/src/resources/css/color-profile/blue-profile.less";

body {
    line-height: 1.5;
}

body #head-wrapper #head #header a.logo {
    margin-top: -20px;
    height: 70px;
}

#main.main {
    ul.collaboration-part-of.only-links,
    ul.collaboration-part-of {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        li:first-child {
            max-width: 250px;
        }
        align-items: baseline;
        li a:not(.button):not(.button-large) {
            padding-left: 0;
            display: inline-block;
            &:before {
                display: none;
            }
            &:hover,
            &:focus,
            &:focus-visible {
                outline: -webkit-focus-ring-color auto 1px;
            }
        }
    }
}
#footer-wrapper #footers {
    column-gap: 40px;
    .col-1-3 {
        width: auto;
    }
    .menu-label {
      margin-top: 7px;
    }
    .col-2-5 {
        margin-top: 0;
    }
}

@media only screen and (max-width: 770px) {
    #head-wrapper {
        padding: 30px 15px 0px 15px;
        margin: 0;
    }
    #vrtx-frontpage #main .grid-container.full-width-picture-bg {
        margin-left: 0px;
    }
    #main ul.collaboration-part-of li:first-child {
         margin-left: 0;
         max-width: 100%; 
    }
}