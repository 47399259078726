/* ---- colors ---- */
@color-light-bg: #7098ff; //violet-blue
@color-dark-bg: #374052;
@color-light: #ffffff;
@color-neutral: #ccc;
@color-neutral-dark: #2b2b2b;
@color-dark: #000000;
@color-placeholder: #ffc0cb;
@headerLangTooltipBg: #312d28;

:root {
  --textColor: @color-dark;
  --infoBoxDefaultColor: @color-dark-bg;
  --alternateRowColor: #E2E5EB; 
  --alternateRowColorHover: #D9DCE4;
  --grid-color-profile: #B2B9CB;
}

#main .grid-container.row-all-colored.grid-color-blue {
  background: var(--grid-color-profile);
}