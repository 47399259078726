/* CSS custom-profile */
:root {
  --alternateRowColor: #eceff8;
  --alternateRowColorHover: #dbe0f1;
  --placeholder-color: @color-dark;
}

// Typography

abbr {
  text-decoration: none;
}

input#search-string-responsive {
  &::-webkit-input-placeholder {
    /* Edge */
    color: var(--placeholder-color);
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--placeholder-color);
  }

  &::placeholder {
    color: var(--placeholder-color);
  }
}

#head-admin-menu-wrapper {
  height: 3em;
}

#head-wrapper {
  min-height: 130px;
  height: auto;
  padding-top: 46px;
  #head {
    column-gap: 2.2em;
    align-items: center;
    #header {
      align-self: center;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      a.logo {
        font-size: 4.2rem;
        display: inline-block;
        width: fit-content;
        line-height: 1.5;
      }
      span {
        font-size: 2.8rem;
        display: inline-block;
        width: fit-content;
        margin-left: 0.4em;
        line-height: 1.5;
      }
    }
    #header-language {
      font-size: 1.6rem;
      text-transform: uppercase;
      align-self: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      margin-right: 0;
      gap: 0.6em;
      span {
        border: 1px solid @color-dark;
        padding: 1px 8px;
        line-height: 1.5;
      }
      #header-language-tooltip {
        text-transform: none;
      }
    }
    #sidebar-toggle-link.sidebar-menu-toggle {
      align-self: center;
      display: flex;
      column-gap: 0.5em;
      align-items: center;
      width: 5em;
      grid-column-end: 4;
      span {
        top: 0;
      }
      &:after {
        height: 1.25em;
        width: 2.1em;
        display: block;
        margin-left: 0;
        background-position-x: right;
      }
    }
  }
}

body.sidebar-menu-wrapper-visible {
  #head-wrapper,
  .sidebar-menu-wrapper .sidebar-menu {
    background-color: var(--grid-color-profile);
  }
  #head-wrapper {
    #head #header {
      #header-tools {
        background-color: transparent;
        .search-form input[type="text"] {
          border-color: @color-dark;
        }
        button.toggle-search {
          display: none;
        }
      }
    }
  }
}

#breadcrumbs {
  height: 68px;
}
#vrtx-frontpage.the-frontpage #breadcrumbs {
  height: 0;
}

body.sidebar-menu-wrapper-visible {
  .vrtx-breadcrumb-menu {
    display: none;
  }
}

#vrtx-program-option-frontpage-change-language-link,
#vrtx-change-language-link,
.vrtx-change-language-link {
  display: none;
}

/* large picture on top */
#main .grid-container.full-width-picture-bg {
  top: 0;
  margin-bottom: 100px;
  background-position: center right !important;
  height: 515px;
  .row {
    padding-top: 120px;
  }
  .vrtx-frontpage-box-picture .vrtx-frontpage-box-picture {
    opacity: 0;
    display: none;
    visibility: hidden;
    img {
      opacity: 0;
      display: none;
      visibility: hidden;
      width: 0;
      height: 0;
    }
  }
  div.mobile-image {
    display: none;
  }
  .vrtx-frontpage-box.large-links {
    opacity: 1;
  }
}

.vrtx-feed ul li .published-date {
  display: block;
}

.vrtx-facts-container {
  background: var(--alternateRowColor);
}

#main {
  #vrtx-semester-links, .navigation-links ul, ul.navigation-links {
    li a {
      background-color: var(--alternateRowColor);
      &:hover, &:focus {
        background-color: var(--alternateRowColorHover);
      }
    }
  }
  .grid-color-grey, .grid-container.row-all-colored {
    #vrtx-semester-links, .navigation-links.grey-box ul, ul.navigation-links {
      li a {
        background-color: white;
        &:hover, &:focus {
          background-color: var(--alternateRowColorHover);
        }
      }
    }
  }
}


// Feed-image-missing
#main .two-column-feed,
#main .three-column-feed {
  .vrtx-feed.with-images li,
  .vrtx-event-component.with-images .vrtx-event-component-item {
    .vrtx-list-item-content:only-child {
      background: @color-light-bg;
    }
  }
}

#vrtx-frontpage #main .promoted-article-feed .with-images .vrtx-list-item-content:only-child {
  background: var(--infoBoxDefaultColor);
  padding: 20px 20px 80px;
}
// -end- Feed-image-missing

.vrtx-frontpage-box.sub-header-box-big.vrtx-frontpage-box-picture .vrtx-frontpage-box-picture:after {
  display: none;
}

ul.collaboration-part-of {
  gap: 40px;
  li.collaboration-part-of-image a {
    height: auto;
    display: flex;
    img {
      height: auto !important;
      width: 100% !important;
      max-width: 250px;
    }
  }
}

body.en {
  #footer-wrapper #footers {
    background: none;
  }
}

#footer-wrapper {
  background: none @color-neutral-dark;
  h2 {
    text-transform: uppercase;
    font-size: 1.2rem;
    line-height: normal;
    margin-top: 8px;
    margin-bottom: 5px;
  }
  #footers {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 40px;
    overflow: visible;
    background: none;
    padding-top: 40px;
    .col-3-5 {
      display: flex;
      column-gap: 60px;
      width: 100%;
      div {
        min-width: 150px;
      }
    }
    .col-2-5 {
      width: 100%;
    }
    #footer-title {
      margin-right: auto;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.5em;
      min-width: 150px;
      a {
        font-size: 3.1rem;
      }
    }
    .footer-content {
      display: grid;
      grid-auto-flow: column;
      column-gap: 60px;
    }
    .eu-footer {
      max-width: 600px;
      margin-bottom: 30px;
    }
    .eu-flag,
    .uio-logo {
      max-width: 250px;
      margin-right: 20px;
      margin-top: 20px;
      display: inline-block;
      a {
        display: inline-block;
      }
      img {
        width: 250px;
      }
    }
    
    #privacy-notification {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 15px;
      background: black;
      z-index: 1;
      div {
        display: flex;
        justify-content: center;
        align-items: baseline;
      }
      button {
        flex-shrink: 0;
        margin-left: 20px;
        width: auto;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .vrtx-dropdown-component {
    .vrtx-dropdown-wrapper {
      position: static;
    }
  }
}
#uio-footer-logo {
  display: none;
}
#footer-wrapper-back-to-uio {
  background-color: @color-neutral-dark;
}

@media print {
  html,
  body {
    height: auto;
  }
  #header-lang,
  body #head-wrapper #head #header #header-tools {
    display: none;
  }
  .the-frontpage:not(.sidebar-menu-wrapper-visible) {
    a.uio-faculty,
    &.faculty .uio-host {
      margin-left: 0;
    }
  }
  .the-frontpage:not(.sidebar-menu-wrapper-visible):not(.faculty) .uio-host {
    margin-left: 0px;
  }

  #head-admin-menu-wrapper,
  .vrtx-login-manage-component.vrtx-dropdown-component.vrtx-dropdown-component-toggled {
    display: none !important;
  }
  #footer-wrapper {
    display: none;
  }
  #footer-wrapper-back-to-uio {
    margin-top: 10px;
  }
  .page:not(:last-child) {
    page-break-after: auto;
  }
}
@media only screen and (max-width: 1300px) {
  #footer-wrapper p {
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
}

@media only screen and (max-width: 1070px) {
  #main .grid-container.full-width-picture-bg {
    top: 0;
    margin-bottom: 100px;
  }
  .the-frontpage:not(.sidebar-menu-wrapper-visible):not(.faculty) .logo {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 768px) {
  #head-wrapper #head {
    column-gap: 1.2em;
    #sidebar-toggle-link.sidebar-menu-toggle {
      width: 2em;
    }
  }
  #main
    .grid-container.full-width-picture-bg
    .vrtx-frontpage-box-picture
    .vrtx-frontpage-box-picture {
    opacity: 1;
    display: block;
    visibility: visible;
    img {
      opacity: 1;
      display: block;
      visibility: visible;
      width: 100%;
      height: auto;
    }
  }
  #vrtx-frontpage.total-main #main #vrtx-frontpage-introduction {
    margin-left: 0;
    margin-right: 0;
  }
  
  #vrtx-frontpage #main .grid-container.full-width-picture-bg {
    margin-bottom: 30px;
  }
  #vrtx-searchview #vrtx-content #vrtx-main-content:only-child {
    width: 100%;
    padding-right: 0;
  }
  .mobile-image:after {
    display: none;
  }
  #footer-wrapper {
    #footer-title {
      margin-bottom: 40px;
    }
    #footers {
      padding: 56px 15px 70px 15px;
      grid-template-columns: 1fr;
      grid-auto-flow: row;
      row-gap: 20px;
      .col-3-5 {
        flex-direction: column;
      }
      .footer-content {
        grid-template-columns: 1fr;
        grid-auto-flow: row;
        row-gap: 20px;
        .col-1-3 .menu-label {
          margin-top: 0;
        }
      }
    }
  }
  #footer-wrapper-back-to-uio {
    background-color: @color-neutral-dark;
    padding-top: 25px;
  }
}